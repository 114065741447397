import styled from "styled-components";
import { colors, sizes } from "../styles/constants";
import { FaEnvelope, FaGithub, FaLinkedin, FaGitlab } from "react-icons/fa";

const SocialsContainer = styled.section`
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  width: 15rem;
  font-size: 2em;
  z-index: 10;
  a {
    transition: color 0.5s ease-in-out;
    &:hover {
      color: ${colors.midBlue};
    }
  }
  @media (max-width: ${sizes.xs}) {
    width: 10rem;
  } ;
`;

const Socials = () => {
  return (
    <SocialsContainer>
      <a href="mailto:devendr4@protonmail.com" target="_blank" rel="noreferrer">
        <FaEnvelope />
      </a>
      <a href="https://github.com/devendr4" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
      <a href="https://gitlab.com/devendr4" target="_blank" rel="noreferrer">
        <FaGitlab />
      </a>
      <a
        href="https://www.linkedin.com/in/angel-altuve-7b65a8174/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin />
      </a>
    </SocialsContainer>
  );
};
export default Socials;
