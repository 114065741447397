//@ts-ignore
import { Image, Transformation, Placeholder } from "cloudinary-react";
import { NavLink } from "react-router-dom";
import { Title } from "../styles/general";
import AboutContainer from "../styles/about";
//@ts-ignore

export const About = () => {
  return (
    <AboutContainer>
      <Title>About Me</Title>
      <section className="personal">
        {/* <img
          height="400"
          src="https://media.licdn.com/dms/image/D4E03AQFUQU6U0mA6_w/profile-displayphoto-shrink_800_800/0/1691614422146?e=1716422400&v=beta&t=23fZtJEzgNU57VBi6R15gIgu7WeM8QOzwAjSQ94Ln18"
        /> */}
        <Image className="profilepic" publicId="profilepic_ec3tqz.jpg">
          <Transformation fetchFormat="auto" quality="auto" height="400" />
          <Placeholder type="pixelate" />
        </Image>
        <span className="text">
          <p>
            Hi! I'm Angel Altuve, a Computer Science (Ingeniería Informática)
            graduate from Universidad Católica Andrés Bello. I have over 4 years
            of professional experience and I consider myself to be skilled in
            both the frontend and backend sides of web development.{" "}
          </p>
          <p>
            I'm always looking forward to learn new technologies and concepts as
            the industry evolves with each passing day.
          </p>

          <p>
            I'm interested in machine learning and deep learning, and I've taken
            some online courses to learn more about these topics. The
            certificates I received after completing these courses can be seen
            <NavLink to="/certifications"> here.</NavLink>
          </p>

          {/*
          <div className="download">
            <Button isActive={false} grow={0}>
              <a href={Cv} target="_blank" rel="noreferrer">
                Download CV
              </a>
            </Button>
          </div>
            */}
        </span>
      </section>

      <section className="education">
        <h2>Education</h2>
        <section className="edu-grid">
          <section>
            <h4>Science Bachelor's Degree</h4>
            <p>Colegio San Antonio de la Florida</p>
            <p>09/2011 - 07/2015</p>
          </section>
          <section>
            <h4>Software Engineering Degree</h4>
            <p>Universidad Católica Andrés Bello</p>
            <p>09/2015 - 10/2021</p>
          </section>
        </section>
      </section>
      <section className="work">
        <h2>Work Experience</h2>
        <section className="work-grid">
          <section>
            <h4>Data Delivery Engineer</h4>
            <p>Admetricks</p>
            <p>10/2023 - 03/2024</p>
          </section>
          <section>
            <h4>Software Engineer</h4>
            <p>Vendible</p>
            <p>12/2020 - 08/2023</p>
          </section>
          <section>
            <h4>Full Stack Developer </h4>
            <p>Telefónica Venezolana, C.A.</p>
            <p>10/2020 - 08/2021</p>
          </section>
          <section>
            <h4>Front-end Developer</h4>
            <p>Asociación Guao de Venezuela</p>
            <p>03/2020 - 09/2020</p>
          </section>
          <section>
            <h4>Translator</h4>
            <p>Traducciones TEP, C.A.</p>
            <p>03/2019 - 01/2020</p>
          </section>
        </section>
      </section>
    </AboutContainer>
  );
};
