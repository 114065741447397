import styled from "styled-components";
import { colors, sizes } from "../styles/constants";
import { Form } from "formik";

export const ContactContainer = styled.section`
  display: grid;
  place-items: center;
`;

export const SpinnerContainer = styled.section`
  display: grid;
  position: fixed;
  min-height: 100%;
  min-width: 100vw;
  margin: -4.5rem -4rem -2rem -4rem;
  place-items: center;
  z-index: 999;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(5px);
  @media (max-width: ${sizes.sm}) {
    margin-left: 0;
  }
`;

export const StyledForm = styled(Form)`
  .fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    padding: 2rem;
    gap: 2rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 3px ${colors.primary};
  }

  button {
    margin-top: 1rem;
    height: 2rem;
    border-radius: 0;
    font-weight: bold;
    grid-column: 1/-1;
    width: 100%;
    background: transparent;
    cursor: pointer;
    border: 1px transparent solid;
    border-color: ${colors.primary};
    color: ${colors.white};
    transition: border-color 0.25s ease-in-out;
    &:hover {
      border-color: ${colors.blue};
    }
    &:focus {
      outline: none;
    }
  }

  .full-width {
    grid-column: 1 / -1;
  }

  input,
  textarea {
    width: 100%;
    background: none;
    letter-spacing: 2px;
    border: none;
    outline-width: 0;
    border-bottom: solid 2px ${colors.orange};
    &:focus {
      outline-color: ${colors.orange};
      outline-style: solid;
    }
  }

  textarea {
    resize: none;
  }
  @media (max-width: ${sizes.xs}) {
    .fieldset {
      grid-template-columns: 1fr;
    }
  } ;
`;
