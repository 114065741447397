//@ts-ignore
import { Image, Transformation, Placeholder } from "cloudinary-react";
import React from "react";
import styled from "styled-components";
//import { Certificate } from "../interfaces/interfaces";
import useCollection from "../hooks/useCollection";
import { sizes } from "../styles/constants";
import { Title } from "../styles/general";
import { Spinner } from "./SpinnerComponent";

export const Certifications = () => {
  const { isLoading, collection } = useCollection({
    name: "certificates",
  });

  const CertGrid = styled.div`
    display: grid;
    gap: 1.5rem;
    transition: transform 0.3s ease-in-out;
    section {
      padding: 0.5rem;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      p {
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
      }
    }
    img {
    }
    @media (min-width: ${sizes.md}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${sizes.lg}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${sizes.sm}) {
      grid-template-columns: 1fr;
    }
    span {
      width: 100%;
      text-align: center;
    }
  `;
  const StyledSubtitle = styled.h2`
    text-align: center;
  `;

  return (
    <>
      <Title>Certifications</Title>
      {isLoading && <Spinner />}

      {!isLoading && <StyledSubtitle>Machine Learning</StyledSubtitle>}
      <br />
      {!isLoading && (
        <CertGrid>
          {collection.map((item, i) => {
            if (item.category === "ml")
              return (
                <section key={i}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Image width="100%" publicId={item.image}>
                      <Transformation
                        fetchFormat="auto"
                        quality="auto"
                        width="800"
                        crop="limit"
                        flags="lossy"
                      />

                      <Placeholder type="pixelate" />
                    </Image>
                  </a>
                  <p> Credential ID: {item.credential} </p>
                </section>
              );
            else {
              return <> </>;
            }
          })}
        </CertGrid>
      )}
      {!isLoading && <StyledSubtitle>Front-end</StyledSubtitle>}
      {!isLoading && (
        <CertGrid>
          {collection.map((item, i) => {
            if (item.category === "frontend")
              return (
                <section key={i}>
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Image width="100%" publicId={item.image}>
                      <Transformation
                        fetchFormat="auto"
                        quality="auto"
                        width="800"
                        crop="limit"
                        flags="lossy"
                      />
                      <Placeholder type="pixelate" />
                    </Image>
                  </a>
                  <p>Credential ID: {item.credential}</p>{" "}
                </section>
              );
            else {
              return <></>;
            }
          })}
        </CertGrid>
      )}
    </>
  );
};
