import styled, { keyframes } from "styled-components";
import { colors, sizes } from "./constants";

type TabProps = {
  isActive: boolean;
  grow: number;
};

export const TabGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Button = styled.button<TabProps>`
  cursor: pointer;
  transition: 900ms;
  border-radius: 500px;
  border: 1px transparent solid;
  border-color: ${(props) => (props.isActive ? colors.orange : colors.primary)};
  color: ${(props) => (props.isActive ? colors.orange : "white")};
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(2px);
  margin: 0 1rem 1rem 1rem;
  font-size: 1.25em;
  flex-grow: ${(props) => props.grow};
  min-width: 6rem;
  text-align: center;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  &:hover {
    border-color: ${colors.orange};
    filter: grayscale(0%);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) => (props.isActive ? "100%" : "0%")};
    transition: 0.3s ease-in height;
    z-index: -1;
    background-color: ${colors.orange};
    opacity: 0.08;
  }
`;

export const SkillGroup = styled.div`
  display: grid;
  margin: 1rem 3.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${sizes.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${sizes.sm}) {
    grid-template-columns: 1fr;
  }
  gap: 2.5rem;
  font-size: 1.2em;
  p {
    margin: 0;
  }
`;

export const Circle = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background-color: ${colors.brightOrange};
`;

type ProgressBarProps = {
  width: string;
};

const ProgressAnimation = keyframes`
  0%{
    width: 0%
  }
  }
  100%{
    width:100%;
  }
`;

const PercentageAnimation = keyframes`
  0%,60%{
    opacity:0
  }
  100%{
    opacity:1
  }
`;
export const ProgressBar = styled.div<ProgressBarProps>`
  display: flex;
  text-align: end;
  height: 0.5rem;
  border-radius: 10rem;
  justify-content: flex-end;
  align-items: center;
  background-color: ${colors.orange};
  width: ${(props) => props.width};
  animation: ${ProgressAnimation} 1.5s ease-out;
  &:before {
    p {
      display: none;
    }
  }
`;

export const FullBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${colors.gray};
  border-radius: 10rem;
`;

export const Knowledge = styled.p`
  margin-top: 1.8rem !important;
  padding-left: 1rem;
  font-size: 0.7em;
  animation: ${PercentageAnimation} 2s ease-in;
`;
