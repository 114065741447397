import styled from "styled-components";
import { sizes, colors } from "./constants";

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  h1 {
    margin-bottom: 1rem;
    grid-column: 1/-1;
  }
  h1,
  h2 {
    flex: 100%;
    text-align: center;
  }

  .line {
    background-color: red;
    height: 15rem;
    position: relative;
    &:after {
      content: "";
      width: 5px;
      top: 0;
      left: 1rem;
      height: 100%;
      background-color: pink;
      position: absolute;
    }

    flex-grow: 1;
  }
  .personal {
    width: 100%;
    display: flex;
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 3px ${colors.primary};
    padding: 1rem;
    grid-column: 1/-1;
    flex-wrap: wrap;
    justify-content: center;
    .text {
      flex: 70%;
      padding: 1rem;
      a {
        color: ${colors.primary};
        text-decoration: none;
      }
    }

    p {
      text-align: justify;
    }
  }
  .work {
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(2px);
    border-top-width: 0;
    box-shadow: 0 0 3px ${colors.primary};
    padding: 1rem;
  }

  .education {
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 3px ${colors.primary};
    border-top-width: 0;
    border-right-width: 0;
    padding: 1rem;
  }
  .work-grid {
    display: grid;
    text-align: center;
    /*grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));*/
    grid-template-columns: 1fr 1fr;
  }

  .edu-grid {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }

  .profilepic {
    width: 400px;
    border-radius: 5px;
    padding: 0.5rem;
  }
  .placeholder {
    height: 300px;
    width: 300px;
    border-radius: 4px;
    padding: 1rem;
    background-color: gray;
  }

  .download {
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${sizes.sm}) {
    width: 100%;
    grid-template-columns: 1fr;

    .edu-grid {
      grid-template-columns: 1fr;
    }

    .work-grid {
      grid-template-columns: 1fr;
    }
    .education {
      flex: 100%;
      border-right-width: 5px;
    }
    .work {
      flex: 100%;
    }
  }
`;
export default AboutContainer;
