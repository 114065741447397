import { createGlobalStyle } from "styled-components";
import { colors, sizes } from "./constants";

const GlobalStyle = createGlobalStyle`

.page-enter {
  opacity: 0.01;
}
h1,h2,h3,h4{
    text-shadow: 0 0  4px white;
}

.page-enter-active {
  opacity: 1;
  transition: all 400ms ease-in;
}

.page-exit {
  opacity: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in, transform 250ms ease-in;
}

:root {
  font-size: 1.375rem;
  color: ${colors.white};
  scroll-behavior:smooth;
  font-family: Rokkitt;
}
button,textarea,input{
  font-size: 1rem;
  font-family: Rokkitt;
  color: ${colors.white};

}

body {
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  background-color: ${colors.darkbg};
  max-width:100vw;
}
h1,h2,h3,h4{
    text-transform: uppercase;
    letter-spacing: 1px;
}

main {
  padding: 4.5rem 4rem 2rem 4rem;
  max-width:100vw;
}

a{
  color:${colors.white}
}

  canvas{ position:fixed;
    z-index:-1;
  }
body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: ${colors.gray};
}

body::-webkit-scrollbar-thumb {
  background: ${colors.gray};
  background: ${colors.orange};
}

.pic {
  width: 10rem;
  height: 10rem;
  border-radius: 500px;
}

  @media (max-width: ${sizes.sm}) {
    :root{
      font-size:1.2rem;
    }
    main{
      margin-left:0;
      padding:1rem;
      max-width:100%;
    }
  }
`;
export default GlobalStyle;
