import "firebase/firestore";
import React, { useState } from "react";
import { Title } from "../styles/general";
import { Button, TabGroup } from "../styles/skills";
import { SkillList } from "./SkillListComponent";

const categories = [
  "languages",
  "frontend",
  "backend",
  "databases",
  "business intelligence",
];
export const Skills = () => {
  const [active, setActive] = useState(0);

  return (
    <div>
      <Title>Skills</Title>
      <TabGroup>
        {categories.map((category, i) => {
          return (
            <Button
              key={i}
              isActive={active === i}
              grow={1}
              onClick={() => setActive(i)}
            >
              {category}
            </Button>
          );
        })}
      </TabGroup>

      {categories.map((category, i) => (
        <SkillList
          key={i}
          category={category.toLowerCase()}
          isActive={active === i}
        />
      ))}
    </div>
  );
};
