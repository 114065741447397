import React, { useState } from "react";
import { BsPersonLinesFill, BsFillGearFill } from "react-icons/bs";
import {
  //  FaMoon,
  FaBriefcase,
  FaEnvelope,
  FaGraduationCap,
  FaHome,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { StyledNavbar } from "../styles/navbar";
import { StyledBurger } from "./BurgerComponent";

export const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <StyledBurger isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <StyledNavbar isOpen={isOpen}>
        <ul>
          <li>
            <NavLink to="/" exact onClick={() => setOpen(false)}>
              <FaHome />
              <span className="link-text">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={() => setOpen(false)}>
              <BsPersonLinesFill />
              <span className="link-text">About Me</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/skills" onClick={() => setOpen(false)}>
              <BsFillGearFill />
              <span className="link-text">Skills</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/works" onClick={() => setOpen(false)}>
              <FaBriefcase />
              <span className="link-text">Portfolio</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/certifications" onClick={() => setOpen(false)}>
              <FaGraduationCap />
              <span className="link-text">Certifications</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={() => setOpen(false)}>
              <FaEnvelope />
              <span className="link-text">Contact</span>
            </NavLink>
          </li>
          <li>
            <span>{/*<FaMoon />*/}</span>
          </li>
        </ul>
      </StyledNavbar>
    </>
  );
};
