export const colors = {
  darker: "#1f1f1f",
  darkbg: "#282828",
  white: "whitesmoke",
  primary: "#bac152",
  orange: "#d65d0e",
  brightOrange: "#fe8019",
  gray: "#928374",
  lightBlue: "#83a598",
  blue: "#076678",
  midBlue: "#458588",
};

export const sizes = {
  xs: "375px",
  sm: "768px",
  md: "1024px",
  lg: "1369px",
};
