import React from "react";
import styled, { keyframes } from "styled-components";
import server from "../server2.jpg";
import Socials from "./SocialsComponent";

const type = keyframes`
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid orange;
  }
  100% {
    border: none;
  }
`;

const type2 = keyframes`
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid orange;
  }
  100% {
    opacity: 1;
    border: none;
  }
`;

const type3 = keyframes` 
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const blink = keyframes`{
  50% {
    border-color: transparent;
  }
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
  background: url(${server}) no-repeat center center fixed;
  background-size: cover;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    opacity: 0.2;
    z-index: 1;
  }
`;
const HomeContainer = styled.div`
  /*
  .css-typing h1,
  h2 {
    border-right: 0.15em solid orange;
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing h1 {
    width: 100%;
    animation: ${type} 2s steps(40, end);
    animation-fill-mode: forwards;
  }

  .css-typing h2:nth-child(2) {
    width: 100%;
    opacity: 0;
    animation: ${type2} 2s steps(40, end);
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }

  .css-typing h2:nth-child(3) {
    width: 100%;
    opacity: 0;
    animation: ${type3} 2s steps(40, end),
      ${blink} 0.5s step-end infinite alternate;
    animation-delay: 4s;
    animation-fill-mode: forwards;
  }

  */
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 5rem);
  justify-content: space-between;
  align-items: center;
  section {
    text-align: center;
    margin-top: 2rem;
  }
  h1 {
    font-size: 3em;
    margin: 0;
    flex: 100%;
  }
  h2 {
    font-size: 2em;
    flex: 100%;
  }
  a {
    font-size: 1em;
  }
  h2 {
    text-transform: capitalize;
  }
`;

export const HomeComponent: React.FC = () => {
  return (
    <>
      <Background />
      <HomeContainer>
        <section>
          <h1>Angel G. Altuve R.</h1>
          <h2>Full Stack Web Developer </h2>
          <h2>Software Engineer</h2>
        </section>
        <Socials />
      </HomeContainer>
    </>
  );
};
