import styled from "styled-components";
import { colors, sizes } from "./constants";

type Props = {
  isOpen: boolean;
};
export const StyledNavbar = styled.nav<Props>`
  height: 3rem;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  z-index: 999;
  margin-top: 0.5rem;
  ul {
    list-style: none;
    border-radius: 500px;
    /*
    background-image: linear-gradient(
      rgba(40, 180, 0, 0.9),
      rgba(184, 184, 184, 0.7) 0,
      transparent
    );*/

    background-color: rgba(31, 31, 31, 0.8);
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: row;
    align-items: center;
    li {
      position: relative;
      a {
        padding: 1.2rem;
        color: ${colors.primary};
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        filter: grayscale(100%);
        transition: filter 300ms ease-in-out;
        svg {
          transition: transform 600ms ease-in-out;
          margin-right: 0.3rem;
        }
        &:hover {
          .link-text {
            opacity: 1;
          }
          filter: grayscale(0%);
        }
      }
      &:last-child {
        margin-top: auto;
      }
    }
  }
  .link-text {
    text-align: center;
    flex: 100%;
    transition: opacity 0.5s ease-in-out;
    text-shadow: 0 0 3px $primary;
    font-size: 0.6em;
    white-space: nowrap;
  }

  .active {
    filter: grayscale(0%) !important;
  }
  @media (max-width: ${sizes.md}) {
    font-size: 1.2em;
    width: 12rem;
    margin-top: 0;
    height: 100vh;
    transform: ${(props) =>
      props.isOpen ? "translateX(0)" : "translateX(-100%)"};
    .link-text {
      position: relative;
      flex: 50%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      opacity: 100;
    }
    ul {
      border-radius: 0;
      flex-direction: column;
      width: 100%;
      li {
        a {
          padding: 1rem;
          width: 8rem;
          flex-direction: row-reverse;
        }
      }
    }
  }
`;
