import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import axiosInstance from "../axiosConfig";
import { Title } from "../styles/general";
import Socials from "./SocialsComponent";
import {
  SpinnerContainer,
  ContactContainer,
  StyledForm,
} from "../styles/contact";
import { Spinner } from "./SpinnerComponent";

export const Contact = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  return (
    <ContactContainer>
      <Title>Contact Me</Title>

      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        ""
      )}

      <Formik
        initialValues={{ name: "", email: "", subject: "", msg: "" }}
        validate={(values) => {
          const errors = {
            name: "",
            email: "",
            subject: "",
            msg: "",
          };
          if (!values.email) {
            errors.email = "Required";
          } else if (!values.name) {
            errors.name = "Required";
          } else if (!values.subject) {
            errors.subject = "Required";
          } else if (!values.msg) {
            errors.msg = "Required";
          }
          if (errors.email || errors.subject || errors.msg || errors.name) {
            setError(true);
          } else {
            setError(false);
          }
          console.log(error);
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log("s");
          if (!error) {
            setLoading(true);
            axiosInstance
              .post("/api/send-email", values)
              .then(() => {
                setSubmitting(false);
                setLoading(false);
                resetForm();
              })
              .catch((err: any) => {
                alert(err);
                setLoading(false);
                setSubmitting(false);
              });
          }
        }}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <section className="fieldset">
              <Field type="text" name="name" placeholder="Name" />{" "}
              <ErrorMessage name="email" component="span" />
              <Field type="email" name="email" placeholder="Email" />{" "}
              <ErrorMessage name="email" component="span" />
              <Field
                type="text"
                name="subject"
                placeholder="Subject"
                className="full-width"
              />{" "}
              <ErrorMessage name="subject" component="span" />
              <Field
                type="textarea"
                className="full-width"
                name="msg"
                placeholder="Message"
                component="textarea"
                rows="10"
              />{" "}
              <ErrorMessage name="msg" component="span" />
              <button type="submit" disabled={error} className="full-width">
                Send Email
              </button>
            </section>
          </StyledForm>
        )}
      </Formik>
      <Socials />
    </ContactContainer>
  );
};
