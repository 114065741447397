import styled from "styled-components";
import { sizes } from "./constants";

export const Title = styled.h1`
  font-size: 3em;
  margin: 0;
  text-align: center;
  transition: ease-in;
  flex: 100%;
  @media (max-width: ${sizes.sm}) {
    margin-top: 2rem;
    font-size: 1.5em;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  span {
    p {
      margin-top: 1rem;
    }
  }
  svg {
    font-size: 1.6em;
    margin-bottom: 0.5rem;
  }
`;
