//@ts-ignore
import { Image, Transformation } from "cloudinary-react";
import { FaCodeBranch } from "react-icons/fa";
import styled from "styled-components";
import useCollection from "../../hooks/useCollection";
import { colors } from "../../styles/constants";
import { Title } from "../../styles/general";
import { Spinner } from "../SpinnerComponent";
const WorkContainer = styled.section`
  a {
    color: white;
    text-decoration: none;
  }
  .src {
    font-size: 0.8em;
    margin-top: 1rem;
  }
  .works-grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .work {
    display: flex;
    padding: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.5rem;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease,
      background 0.5s ease-in-out;
    &:hover {
      img {
      }
      border: 2px solid rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(2px);
      box-shadow: 0 0 3px ${colors.primary};
      transform: scale(1.05);
    }
    img {
      flex: 100%;
    }
    h4,
    p {
      flex: 100%;
      text-align: center;
      margin: 0;
      margin-top: 1rem;
      padding: 0;
    }
  }
`;

const Works = () => {
  const { isLoading, collection } = useCollection({
    name: "works",
  });
  return (
    <WorkContainer>
      <Title>Portfolio</Title>

      {isLoading && <Spinner />}
      <section className="works-grid">
        {collection.map((item, index) => {
          return (
            <section className="work" key={index}>
              <a href={item.url} target="_blank" rel="noreferrer">
                <Image publicId={item.img} width="100%" height="auto">
                  <Transformation
                    fetchFormat="auto"
                    quality="auto"
                    crop="fill"
                    width="400"
                    height="300"
                    flags="lossy"
                  />
                </Image>
              </a>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <a href={item.src} className="src">
                <FaCodeBranch /> Source
              </a>
            </section>
          );
        })}
      </section>
    </WorkContainer>
  );
};
export default Works;
