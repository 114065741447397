// @ts-ignore
import { CloudinaryContext } from "cloudinary-react";
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { About } from "./components/AboutComponent";
import { Certifications } from "./components/CertificationsComponent";
import { Contact } from "./components/ContactComponent";
import { HomeComponent } from "./components/HomeComponent";
import { Navbar } from "./components/NavbarComponent";
//import ParticleBackground from "./components/ParticlesComponent";
import { Skills } from "./components/SkillsComponent";
import Works from "./components/Works/WorkComponent";
import GlobalStyle from "./styles/globalStyles";

function App() {
  const location = useLocation();
  return (
    <CloudinaryContext cloudName="devendr4">
      <GlobalStyle />
      <Navbar />{" "}
      <main>
        {/*        <ParticleBackground />*/}
        <TransitionGroup>
          {" "}
          <CSSTransition
            key={location.key}
            classNames="page"
            timeout={300}
            exit={false}
          >
            <Switch>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/skills">
                <Skills />
              </Route>
              <Route path="/certifications">
                <Certifications />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/works">
                <Works />
              </Route>
              <Route path="/">
                <HomeComponent />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </main>
    </CloudinaryContext>
  );
}

export default App;
