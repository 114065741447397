import React from "react";
import { skillData } from "../data";
import {
  ProgressBar,
  Circle,
  FullBar,
  SkillGroup,
  Knowledge,
} from "../styles/skills";

import { FlexContainer } from "../styles/general";
type Props = {
  category: string;
  isActive: boolean;
};

export const SkillList = ({ category, isActive }: Props) => {
  if (isActive) {
    return (
      <SkillGroup>
        {skillData.map((skill, i) => {
          if (category === skill.category) {
            return (
              <FlexContainer key={skill.name + i}>
                <span>
                  <p>{skill.name}</p>
                </span>
                <skill.icon />
                <FullBar>
                  <ProgressBar width={skill.knowledge + "%"}>
                    <Knowledge>{skill.knowledge + "%"}</Knowledge>
                    <Circle />
                  </ProgressBar>
                </FullBar>
              </FlexContainer>
            );
          } else {
            return <></>;
          }
        })}
      </SkillGroup>
    );
  } else return <></>;
};
